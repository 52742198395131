@font-face {
  font-family: 'Avenir';
  font-style: 'normal';
  font-weight: 400;
  src: url('./fonts/avenir/Avenir-Book.ttf');
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Avenir', sans-serif;
}
#root {
  height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
